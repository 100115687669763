<template>
  <div class="login">
    <mt-header :title="title">
      <call-phone slot="right" />
    </mt-header>
    <div class="welcome">{{ $t('welcome') }}</div>
    <div class="logo">
      <img src="../../assets/login_img_1.png">
    </div>
    <div class="title">{{ $t('projectName') }}</div>
    <div class="bar">
      <img src="../../assets/login_img_2.png">
    </div>
    <div class="box">
      <div class="content">
        <mt-navbar v-model="active">
          <mt-tab-item id="login">{{ $t('login') }}</mt-tab-item>
          <!-- <mt-tab-item id="register">{{ $t('register') }}</mt-tab-item> -->
        </mt-navbar>
        <mt-tab-container v-model="active" :swipeable="true">
          <mt-tab-container-item id="login">
            <login v-model="active" />
          </mt-tab-container-item>
          <mt-tab-container-item id="register">
            <register v-model="active" />
          </mt-tab-container-item>
          <mt-tab-container-item id="password">
            <password v-model="active" />
          </mt-tab-container-item>
        </mt-tab-container>
      </div>
    </div>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import login from './Login'
import register from './Register'
import password from './Password'
export default {
  name: 'User',
  components: {
    callPhone,
    login,
    register,
    password,
  },
  data() {
    return {
      active: this.$route.params.id
    }
  },
  computed: {
    title() {
      if (this.active == 'login') {
        return this.$t('login')
      } else if (this.active == 'register') {
        return this.$t('register')
      } else if (this.active == 'password') {
        return this.$t('forgetPassword')
      }
      return ''
    }
  },
  created() {

  }
}
</script>

<style scoped lang="scss">
.login {
  .mint-header {
    background-color: white;
    color: #000;
  }
  .welcome {
    text-align: center;
    font-size: 38px;
    font-weight: bold;
    color: #6B0487;
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.18);
  }
  .logo {
    height: 60px;
    text-align: center;
    img {
      height: 100%;
    }
  }
  .bar {
    padding: 0px 20px;
    img {
      width: 100%;
    }
  }
  .title {
    text-align: center;
    font-size: 21px;
    font-weight: bold;
    color: #650188;
    line-height: 24px;
    text-shadow: 0px 3px 3px rgba(34, 35, 40, 0.35);
  }
  .box {
    position: relative;
    .content {
      margin: 0px 10px 20px 10px;
      border-radius: 10px;
      background: white;
      padding: 10px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12);
      .mint-navbar {
        width: 180px;
        .mint-tab-item {
          padding: 10px 0;
          /deep/ .mint-tab-item-label {
            font-size: 14px;
          }
        }
      }
      .mint-tab-container {
        padding: 20px 0px;
      }
    }
  }
}
</style>
